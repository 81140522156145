import PropTypes from "prop-types"
import React from "react"
// import logo from '../images/logo_white.png'
import {Navbar, Nav} from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./header.css"

const Header = ({ siteTitle }) => (
  <Navbar className="header-bg" variant="dark" expand="lg">
    <Navbar.Brand href="/">
      <>
        {/*<img className="logo-nav" src={logo} alt="logo" />
        <Img fixed={query.file.childImageSharp.sizes.src} />*/}
        <Image className="logo-nav" />
        <p className="nav-title">{ siteTitle }</p>
      </>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />

    <Navbar.Collapse id="basic-navbar-nav">
      <Nav activeKey="/" className="navbar-nav ml-auto"
        onSelect={selectedKey => (`selected ${selectedKey}`)} >
        {/*<Nav.Link href="/">Home</Nav.Link>*/}
        <Nav.Link href="#things-to-do">Things to do</Nav.Link>
        <Nav.Link href="#places">Places to Stay</Nav.Link>
        <Nav.Link href="#tips">Travel Tips</Nav.Link>
        <Nav.Link href="#about">About</Nav.Link>
      </Nav>
    </Navbar.Collapse>  
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo_white.png" }) {
        childImageSharp {
          fixed(width: 70, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.placeholderImage.childImageSharp.fixed} style={{zIndex:0}}/>
}