import React from 'react';

const style = {
    // width: '100%',
    height: '60px',
    backgroundColor: 'red',
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  };

const Footer = () => (
    <footer className="navbar-fixed-bottom" style={style}>
        <div className="container">
            <div className="row">
                <span>© {new Date().getFullYear()} Monkey Mind Adventures</span>
            </div>
        </div>
    </footer>
)

export default Footer;